#firmware-form[data-v-c7218c2c] .changelog label {
  display: block;
}
#firmware-form[data-v-c7218c2c] .changelog-preview {
  /* margin: -2px; */
  border: 1px solid lightgray;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 11px;
  padding-right: 11px;
  min-height: 150px;
}
#firmware-form[data-v-c7218c2c] .help {
  font-weight: bold;
}
#firmware-form[data-v-c7218c2c] .help::before {
  content: '<';
}
#firmware-form[data-v-c7218c2c] .help::after {
  content: '>';
}
