#logbook-form[data-v-0add35d4] .content label {
  display: block;
}
#logbook-form[data-v-0add35d4] .content-preview {
  /* margin: -2px; */
  border: 1px solid lightgray;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 11px;
  padding-right: 11px;
  min-height: 150px;
}
