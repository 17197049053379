.equipment-container[data-v-b48af56d] {
  border-radius: 1em;
}
.equipment-container.errors[data-v-b48af56d] {
  background-color: #fff2f0;
  border-color: #ffccc7;
}
.equipment-container.unavailable[data-v-b48af56d] {
  background-color: #fffbe6;
  border-color: #ffe58f;
}
